import { render, staticRenderFns } from "./chance.vue?vue&type=template&id=02c01ae8&scoped=true"
import script from "./chance.vue?vue&type=script&lang=js"
export * from "./chance.vue?vue&type=script&lang=js"
import style0 from "./chance.vue?vue&type=style&index=0&id=02c01ae8&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "02c01ae8",
  null
  
)

export default component.exports