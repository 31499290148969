<template>
  <v-app app>
    <div class="sud">
      <v-divider></v-divider>
      <div class="title ml-4 my-8">РЕШЕНИЯ СУДОВ. ПРИМЕРЫ</div>
      <v-divider></v-divider>
      <section class="pl-4 pt-4">
        <h4>Лишают прав и нужны примеры выигранных дел?</h4>
        <p>
          Раньше приходилось днями собирать постановления и решения по сайтам судов и в открытых источниках. Часто случалось так, что юридические компании и псевдоюристы выдавали чужие победы и чужие выигранные дела за свои, вводили автовладельцев в заблуждение.
          <br>
          <br>
          Но сейчас все стало проще. Одна заявка и наш юрист не просто пришлет Вам подборку по интересующей Вас статье КоАП, но и найдет реально похожие дела на Ваше.
        </p>
        <h4 class="mt-6">Что для этого нужно?</h4>
        <p>Оставляете заявку.</p>
        <p>Рассказываете юристу ситуацию, предоставляете (если есть) материалы дела (протоколы и вообще все что есть)</p>
        <p>Юрист проанализирует материалы, найдет похожие выигранные дела с основаниями и предоставит их Вам</p>
        <p>И, да, это все бесплатно!</p>
        <h4 class="mt-6">Почему?</h4>
        <p>Потому что мы – некоммерческая организация!</p>
        <p>Пользуйтесь и выигрывайте! Возвращайте права!</p>
        <div class="middle__wrap mt-8">
          <div class="middle__container">
            <div class="middle__container-title">Получить решения судов по аналогичным делам</div>
            <div class="middle__container-input-wrap">
              <input data-inputmask="'mask': '+9(999)999-99-99'" v-model="waitBtn.btn1.value.tel" placeholder="Телефон" name="tel" type="text">
              <input v-model="waitBtn.btn1.value.email" placeholder="E-mail" name="email" type="text">
              <div @click="send(waitBtn.btn1)" class="btn">Пришлите</div>
            </div>
          </div>
        </div>
      </section>
    </div>
    <notice
      v-if="isPopup"
      @removePopup="removePopup"
      :isPopup="isPopup"
      :title="title"
      :subtitle="subtitle"
    />
  </v-app>
</template>

<script>
import notice from './notification'
import api from '../api/api'
export default {
  name: 'Resheniya',
  components: { notice },
  data () {
    return {
      waitBtn: {
        btn1: { name: 'Пришлите', title: 'Получить решения судов по аналогичным делам', value: { tel: null, email: null } }
      },
      isPopup: false,
      title: null,
      subtitle: null
    }
  },
  methods: {
    removePopup () {
      this.isPopup = false
    },
    send (e) {
      console.log(Boolean(e.value.email))
      if (!e.value.email) {
        this.isPopup = true
        this.title = 'Вам необходимо указать email'
        this.subtitle = 'Пожалуйста, укажите Вашу почту. Это необходимо для более качественного предоставления услуг'
      } else if (!e.value.tel) {
        this.isPopup = true
        this.title = 'Вам необходимо указать Ваш номер телефона'
        this.subtitle = 'Пожалуйста, укажите Ваш телефон. Это необходимо для более качественного предоставления услуг. Ваши данные надежно защищены'
      } else {
        const reg = '^\\+\\d{1,3}\\s?\\(\\d{3}\\)\\s?\\d{3}(-\\d{2}){2}$' // для проверки правильности введенного номера
        if (e.value.tel.match(reg) !== null) {
          const data = {
            name: '',
            email: e.value.email,
            phone: e.value.tel,
            additional: '',
            website: 'magistral-avtourist.ru',
            generate: 'magistral-avtourist.ru',
            city: '',
            note: 'Заявка с magistral-avtourist.ru'
          }
          api.importLead(data)
            .then(() => this.$router.push({ name: 'Thanks' }))
        } else {
          this.isPopup = true
          this.title = 'Номер не корректный!'
          this.subtitle = 'Пожалуйста, укажите правильно номер вашего телефона. Ваши данные надежно защищены.'
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
  h4 {
    color: rgba(0,0,0,.7);
    margin-bottom: 15px;
  }

  .middle__wrap {
    display: flex;
    flex-wrap: wrap;
    .middle__container {
      display: flex;
      justify-content: space-between;
      width: 100%;
      align-items: center;
      border-bottom: 1px solid #FFED00;
      padding-top: 12px;
      padding-bottom: 20px;
      transition: all 0.5s;
      z-index: 200;
      @media (max-width: 1200px) {
        flex-wrap: wrap;
      }
      .middle__container-title {
        @media (max-width: 1200px) {
          margin-bottom: 15px;
        }
        span {
          font-weight: bold;
          font-size: 20px;
          line-height: 27px;
        }
      }
      .middle__container-input-wrap {
        display: flex;
        @media (max-width: 1200px) {
          flex-direction: column;
          width: 100%;
        }
        input {
          background: #FFFFFF;
          border-radius: 6px;
          padding: 10px 6px;
          border: 1px solid rgba(0,0,0,0.3);
          margin-right: 10px;
          transition: all .3s;
          @media (max-width: 1200px) {
            height: 20px;
            margin-bottom: 10px;
            width: 100%;
          }
        }
        .btn {
          background: linear-gradient(0deg, rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0.75)), #FFFFFF;
          border-radius: 6px;
          color: #FFED00;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 180px;
          min-height: 40px;
          z-index: 100;
          @media (max-width: 1200px) {
            height: 40px;
            margin: 0 auto;
          }
        }
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
</style>
