<template>
  <div class="thanks">
    <div class="container">
      <div class="title">
        <p class="head-title">СПАСИБО ЗА ЗАЯВКУ!</p>
        <p class="text-center">
          НАШ ЮРИСТ ПРОАНАЛИЗИРУЕТ ВАШЕ ОБРАЩЕНИЕ И ПОМОЖЕТ ВЕРНУТЬ ПРАВА!<br/>
          МЫ СВЯЖЕМСЯ С ВАМИ ОЧЕНЬ ОПЕРАТИВНО!
        </p>
      </div>
      <div class="rrrrr">
        <div class="messengers">
          <div class="title">
            <p>Выберите удобный способ связи</p>
          </div>
          <div class="button-wrap">
            <v-btn dark width="130" class="btn" color="primary" href="https://wa.me/79250194968?text=Начать"><v-icon>mdi-whatsapp</v-icon> WhatsApp</v-btn>
            <v-btn dark width="130" class="btn" color="primary" href="https://t.me/Avtourist_PAU_bot">Telegram</v-btn>
            <v-btn dark width="130" class="btn" color="primary" href="viber://pa?chatURI=avtourist-bot&context=welcome/">Viber</v-btn>
            <v-btn dark width="130" class="btn" color="primary" href="https://vk.com/write-7375007">VK</v-btn>
          </div>
        </div>
        <p class="mb-10">
          <v-btn
            rounded
            dark
            color="yellow"
            class="black--text mt-10"
            style="font-size: 20px"
            width="250px"
            height="50px"
            @click="$router.push({ name: 'Home' })"
          >
            На главную
          </v-btn>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import api from '../api/api'

export default {
  name: 'Thanks',
  data () {
    return {
      form: {
        name: null,
        email: null,
        city: null,
        phone: null
      },
      nameRules: [
        v => !!v || 'Введите город'
      ],
      phoneRules: [
        value => !!value || 'Обязательное поле',
        value => (Boolean(Number(value))) || 'Введите только цифры'
      ],
      emailRules: [
        v => !!v || 'E-mail обязательное поле',
        v => /.+@.+/.test(v) || 'E-mail введен не правильно'
      ],
      validForm: false
    }
  },
  methods: {
    send () {
      if (this.form.phone) {
        const data = {
          name: this.form.name,
          email: this.form.email,
          phone: this.form.phone,
          additional: '',
          website: 'magistral-avtourist.ru',
          generate: 'magistral-avtourist.ru',
          city: '',
          note: 'Заявка с magistral-avtourist.ru'
        }
        api.importLead(data)
          .then(() => this.$router.push({ name: 'Thanks' }))
      }
    },
    reloadPage () {
      document.location.reload(true)
      localStorage.reloadPage = false
    }
  },
  mounted () {
    if (localStorage.reloadPage) return false
    else localStorage.reloadPage = true
    document.addEventListener('DOMContentLoaded', localStorage.reloadPage && this.reloadPage())
  }
}
</script>

<style scoped lang="scss">
html {overflow: auto}
.rrrrr {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.messengers {
  max-width: 40%;
  margin: 0 auto;
  margin-bottom: 150px;
  .title {
    margin-top: 30px!important;
    margin-bottom: 30px!important;
    text-align: center;
  }
  @media (max-width: 1200px) {
    max-width: 80%;
  }
  .button-wrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    .btn {
      margin-right: 6px;
      margin-bottom: 6px;
    }
  }
}
.head-title {
  font-size: 40px;
  opacity: .7;
  margin-bottom: 40px;
  text-align: center;
}
p.text-center {
  text-align: center;
}
.thanks {
  .title {
    margin-top: calc(50vh - 250px);
    &:last-child p {
      @media (max-width: 600px) {
        font-size: 14px;
      }
    }
  }
}
</style>
